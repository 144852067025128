export enum AppRoutes {
  HOME = '/',
  SIGNIN = '/SignIn',
  SIGNUP = '/SignUp',
  WELCOME = '/Welcome',
  CONFIRM_EMAIL = '/ConfirmEmail',
  NOT_FOUND = '/notfound',
  ERROR = '/error',
  UNHANDLED_ERROR = '/unhandledError',
  OTHER = '*',
}
